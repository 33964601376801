










































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { DroitsEnum } from "@/api/models/enums/droitsEnum";
import { required, min, max } from "@validations";
import {
  BButton, BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormRadioGroup
} from "bootstrap-vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { DossierRapideCreateModel } from "@/api/models/dossiers/dossier";
import Cleave from 'vue-cleave-component'
import { ContratRapideCreateModel } from "@/api/models/dossiers/situationProfessionnelle";
import { Entreprise } from "@/api/models/options/entreprises/entreprise";
import { Site } from "@/api/models/options/entreprises/site";
import { Secteur } from "@/api/models/options/entreprises/secteur";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue"
import { TenantPreferenceEnum } from "@/api/models/enums/tenantPreferencesEnum";
import { EnumTenants } from "@/api/models/enums/enumTenants";
import appSettings from "@/appSettings";
@Component({
  components: {
    ValidationObserver,
    BButton,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    Cleave,
    BInputGroup,
    SearchableVueSelect,
    RessifnetDateInput,
    BFormRadioGroup
  },
})
export default class CreateAccompagnementIndividuel extends Vue {
  tenantId = appSettings.getTenantId();
  tenants = EnumTenants;

  droitsEnum = DroitsEnum
  dossierExistantResult: PaginatedList<any> | null = null;
  searchLoading = false;
  model: DossierRapideCreateModel = {}

  required = required;
  min = min;
  max = max;

  contratRapide: ContratRapideCreateModel = {
    brancheLibelle: "Aucune branche selectionnée",
    regionLibelle: "Aucune région selectionnée",

    totalCountEntreprise: 0,
    listeEntreprises: [],

    totalCountSite: 0,
    listeSites: [],

    totalCountSecteur: 0,
    listeSecteurs: [],
  }

  timeout: any;

  options: any = [
    { text: "Masculin", value: 1 },
    { text: "Féminin", value: 0 },
    { text: "Non connu/Autres", value: null },
  ];

  ssnMask = {
    blocks: [1, 2, 2, 2, 3, 3, 2]
  }

  created(){
    this.listenUserInteraction();
  }

  get tenantPreferences() {
    return this.$store.state.user.tenant_preferences
  }

  get preference() {
    return this.tenantId == EnumTenants.ACTIS;
  }

  @Watch("model.nom")
  async nomChange() {
    await this.checkDossierExistant();
  }

  async checkDossierExistant() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      if (this.model.nom && this.model.prenom) {
        await this.$http.ressifnet.dossiers
          .search({
            nom: this.model.nom,
            prenom: this.model.prenom,
          })
          .then((response: PaginatedList<any>) => {
            this.$emit('dossiers-existants', response);
            this.dossierExistantResult = response;
          });
      }
    }, 3000);
  }
  

  async searchEntreprises(params: any, item: ContratRapideCreateModel) {
    if (this.model.entrepriseId == null || this.model.entrepriseId == undefined){
      if (params?.reset)
        item.listeEntreprises = item.listeEntreprises?.filter(e => e.id == item.entrepriseId);

      await this.$http.ressifnet.entreprises.paginatedList(params.pageNumber, params.pageSize, params.search)
        .then((res: PaginatedList<Entreprise>) => {
          item.totalCountEntreprise = res.totalCount
          item.listeEntreprises = item.listeEntreprises!.concat(res.items.filter(ri => !item.listeEntreprises!.some(lsi => lsi.id == ri.id)))
          if (item.listeEntreprises.length === 1){
            this.model.entrepriseId = item.listeEntreprises[0].id;
            this.onEntrepriseSelected(null)
          }
          if(this.model.entrepriseId != undefined) this.searchSites(null, item);
        })
    }
  }

   async searchSites(params: any, item: ContratRapideCreateModel) {
    if (this.model.siteId == null || this.model.siteId == undefined){
      if (!params || params?.reset) {
        item.listeSites = item.listeSites?.filter(e => e.id == item.siteId);
        item.totalCountSite = item.listeSites?.length
      }

      await this.$http.ressifnet.sites
        .paginatedList(this.model.entrepriseId, params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search)
        .then((res: PaginatedList<Site>) => {
          item.totalCountSite = res.totalCount
          item.listeSites = item.listeSites!.concat(res.items.filter(ri => !item.listeSites!.some(lsi => lsi.id == ri.id))
            .map((x: Site) => <Site>{
              ...x,
              completeLabel: `${x.libelle} (${x.regionLibelle})`
            })
          )
          if (item.listeSites.length === 1){
            this.model.siteId = item.listeSites[0].id;
            this.onSiteSelected(null, item)
          }
          if (this.model.siteId != undefined) this.searchSecteurs(null, item);
        })
    }
  }

  async searchSecteurs(params: any, item: ContratRapideCreateModel) {
    if (this.model.secteurId == null || this.model.secteurId == undefined){
      if (!params || params?.reset) {
        item.listeSecteurs = item.listeSecteurs?.filter(e => e.id == item.secteurId);
        item.totalCountSecteur = item.listeSecteurs?.length
      }

      await this.$http.ressifnet.secteurs
        .paginatedListForSite(this.model.entrepriseId, this.model.siteId, params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search)
        .then((res: PaginatedList<Secteur>) => {
          item.totalCountSecteur = res.totalCount
          item.listeSecteurs = item.listeSecteurs!.concat(res.items.filter(ri => !item.listeSecteurs!.some(lsi => lsi.id == ri.id)))
          item.secteurId = item.listeSecteurs.length === 1 ? item.listeSecteurs[0].id : undefined;
          this.model.secteurId = item.listeSecteurs.length === 1 ? item.listeSecteurs[0].id : undefined;
        })
    }
  }

  onSiteSelected(val: any, item: ContratRapideCreateModel) {
    if (val) {
      const site = item.listeSites?.filter(s => s.id == val)[0]
      item.brancheLibelle = site?.brancheLibelle ?? "Ce site n'a pas de branche"
      item.regionLibelle = site?.regionLibelle ?? "Ce site n'a pas de région"
    }
    else {
      item.brancheLibelle = "Aucune branche selectionné"
      item.regionLibelle = "Aucune région selectionné"
    }
    this.model.secteurId = undefined
  }

  onClearEntreprise(item: ContratRapideCreateModel) {
    this.model.entrepriseId = undefined
    this.model.siteId = undefined
    this.model.secteurId = undefined

    item.listeSites = []
    item.listeSecteurs = []

    this.searchEntreprises(null, item)
  }

  onClearSite(item: ContratRapideCreateModel) {
    this.model.siteId = undefined
    this.model.secteurId = undefined
    item.listeSecteurs = []

    this.searchSites(null, item)
  }


  async defaultDate() {
    this.model = {
      ...this.model,
      dateNaissance: new Date("01/01/1900").toLocaleString()
    }
    this.$swal({
      title: 'Date de naissance par défaut',
      text: 'Veuillez prendre note qu\'en saisissant la date de naissance avec une valeur par défaut, celle-ci ne sera pas utilisable dans les statistiques',
      icon: 'warning',
      customClass: {
        confirmButton: 'btn btn-warning',
      },
      buttonsStyling: false,
      confirmButtonText: 'J\'en prends note',
    })
  }

 listenUserInteraction() {
    window.addEventListener("beforeunload", function (event) {
      event.returnValue =
        "Vous avez des modifications non sauvegardées. Êtes-vous sûr de vouloir quitter la page ?";
    });
  }

 async beforeRouteLeave(to: any, from: any, next: any) {
    var isValid = await (this.$refs['createDossierRapide'] as any).validate();
    if (
      ( this.model?.nom ||
      this.model?.prenom ) && !isValid
      ) {
      let answer = window.confirm(
        "Vous avez des modifications non sauvegardées. Êtes-vous sûr de vouloir quitter la page ?"
      );
      if (answer) {
        try {
          next();
        } catch (err) {
          next(false);
        }
      } else {
        next(false);
      }
    } else {
      next();
    }
  }

  
  async saveDossier(){
    await this.$http.ressifnet.dossiers
      .postRapide(this.model)
      .then(async (response: string) => {
          this.$swal({
            title: 'Création d\'un dossier',
            text: 'Vous avez créé un dossier, merci de prendre vos dispositions en termes de protection des données (RGPD) !',
            icon: 'warning',
            customClass: {
              confirmButton: 'btn btn-warning',
            },
            buttonsStyling: false,
            confirmButtonText: 'Je m\'y engage',              
          })

          this.$router.push({ name: 'details-accompagnement-individuel', params: { id: response } })
          this.searchLoading = false
        },
        (error: any) => {
          if (error.response.data.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
            this.searchLoading = false;
          }
        }
      );
  }
 
  setForm() {
    (this.$refs['CiviliteForm'] as any).model = {
      sexe: null,
      permis: false
    };
    (this.$refs['CoordonneesForm'] as any).model = {};
    (this.$refs['DroitStatusForm'] as any).model = {
      recoRQTH: false,
      recoInvalidite: false,
      recoIPP: false,
      categorieInvaliditeId: null,
      tauxIPPId: null,
    };
    (this.$refs['SituationProForm'] as any).model = [
      {
        brancheLibelle: "Aucune branche selectionné",
        regionLibelle: "Aucune région selectionné",

        totalCountEntreprise: 0,
        listeEntreprises: [],

        totalCountSite: 0,
        listeSites: [],

        totalCountSecteur: 0,
        listeSecteurs: [],

        horaireId: null,
        typeContratId: null,
        categorieSocioProId: null,
        tempsTravailId: null
      },
    ];
  }

  anonymizeDossier() {
    (this.$refs['CiviliteForm'] as any).model = {
      ...(this.$refs['CiviliteForm'] as any).model,
      sexe: null,
      permis: false,
      prenom: "ANONYME",
      nom: "ANONYME",
      dateNaissance: new Date("01/01/1900").toLocaleString()
    };
    (this.$refs['CoordonneesForm'] as any).model = {
      telephone1: "0000000000"
    };
  }

  async onEntrepriseSelected(indexItem: any) {

    this.contratRapide.listeSites = []
    this.contratRapide.listeSecteurs = []
    this.contratRapide.siteId = undefined
    this.contratRapide.secteurId = undefined
    this.contratRapide.brancheLibelle = "Aucune branche selectionné"
    this.contratRapide.regionLibelle = "Aucune région selectionné"

    this.onClearSite(this.contratRapide)

    await this.searchSites(null, this.contratRapide);

    await this.searchSecteurs(null, this.contratRapide)
  }
}

